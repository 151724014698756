import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Button, Checkbox, FormControlLabel } from '@material-ui/core';
import { Divider } from '@material-ui/core';
import Alert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { GET_COURSE, USER_REFFERAL, PP } from '../../api/paymentCheckout';
import { useMediaQuery } from '@mui/material';

const StyledAlert = styled(Alert)({
  backgroundColor: '#6A58C6',
  fontSize: '8px',
  color: '#fff',
  height: '30px',
  margin: '10px 0 10px 0',
  '& .MuiAlert-icon': {
    color: '#fff',
    fontSize: '16px'
  },
});

const PaymentCard = ({ selectedCode, isCouponApplied, course, refr }) => {
  const params = useParams();
  const [limit, setLimit] = useState('');
  const [total, setTotal] = useState(0);
  const [dis, setDiscount] = useState(100);
  const [est, setEst] = useState(0);
  const isMobile = useMediaQuery('(max-width:600px)');
  const [user, setUser] = useState({});
  const [includeInterviewData, setIncludeInterviewData] = useState(false);

  const areThreeCoursesPresent = (cart) => {
    if (!cart || !Array.isArray(cart)) {
      return false;
    }

    const courseIdsToCheck = ["64fb08d372d0fde41877df63", "64fb15b672d0fde41877e193", "64fb105b72d0fde41877e101"];

    for (const courseIdToCheck of courseIdsToCheck) {
      let isPresent = false;

      for (const cartItem of cart) {
        if (cartItem.courseId === courseIdToCheck) {
          isPresent = true;
          break;
        }
      }

      if (!isPresent) {
        return false;
      }
    }

    return true;
  };

  useEffect(() => {
    axios
      .get(USER_REFFERAL + params.userid)
      .then((response) => {
        setUser(response.data.data);
        setDiscount(areThreeCoursesPresent(response.data.data.cart) ? 100 : 0);
        const totalSum = response.data.data.cart.reduce((acc, cart) => acc + cart.price, 0);
        setTotal(areThreeCoursesPresent(response.data.data.cart) ? totalSum - dis : totalSum);
        setEst(totalSum);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [refr]);

  useEffect(() => {
    let discount = dis;
    let updatedTotal = est;

    if (selectedCode.length !== 0) {
      discount = est * selectedCode.details.discountpercentage;
      discount = Math.round(discount, 2);
      if (discount > selectedCode.details.maximumdiscountvalue) {
        setLimit(`Maximum Discount: ₹${selectedCode.details.maximumdiscountvalue}`);
        discount = selectedCode.details.maximumdiscountvalue;
      } else {
        setLimit('');
      }

      updatedTotal = total - discount;
      updatedTotal = Math.round(updatedTotal, 2);
    }

    if (includeInterviewData) {
      updatedTotal += 150;
    }

    setDiscount(areThreeCoursesPresent(user.cart) ? 100 + discount : discount);
    setTotal(updatedTotal);
  }, [isCouponApplied, includeInterviewData]);

  const handlePayNow = () => {
    axios
      .post(PP + "/" + params.id + "/" + params.userid, { total: total, courseIds: user.cart.map(cart => cart.courseId) })
      .then(response => {
        const redirectUrl = response.data.data.instrumentResponse.redirectInfo.url;
        window.location.replace(redirectUrl);
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <Card
      variant="standard"
      style={{
        width: '240px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: '16px',
        padding: '16px',
        marginLeft: !isMobile && '24px',
        height: '70%',
      }}
    >
      <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <div style={{ width: '100%', margin: 'auto' }}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Typography variant="subtitle1" color={'grey'}>Total</Typography>
            <Typography>₹{est}</Typography>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Typography variant="caption" fontWeight={'10'}>Discount</Typography>
            <Typography variant="caption" fontWeight="10">₹{dis}</Typography>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '10px' }}>
            <Typography variant="caption" fontWeight={'thin'}>Coupon Applied</Typography>
            <Typography variant="caption" fontWeight="10">
              {isCouponApplied && selectedCode ? selectedCode.code : '-'}
            </Typography>
          </div>

          {includeInterviewData && (
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '10px' }}>
              <Typography variant="caption" fontWeight={'thin'}>Mocktest</Typography>
              <Typography variant="caption" fontWeight={'thin'}>₹150</Typography>
            </div>
          )}

          <Divider style={{ marginBottom: '10px' }} />

          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
            <Typography variant="caption" fontWeight="10" style={{ marginRight: '8px' }}>
              Add Interview Practice Mocktest for Rs 150/-
            </Typography>
            <Checkbox
              checked={includeInterviewData}
              onChange={() => setIncludeInterviewData(!includeInterviewData)}
              color="primary"
            />
          </div>

          <Divider style={{ marginBottom: '10px' }} />

          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Typography variant="subtitle1" color={'#6A58C6'}>Total</Typography>
            <Typography variant="subtitle1" color={'#6A58C6'}>₹{total}</Typography>
          </div>

          <Button
            variant="contained"
            onClick={handlePayNow}
            style={{ marginTop: '16px', width: '100%', borderRadius: '14px', backgroundColor: '#6A58C6', color: '#fff' }}
          >
            PROCEED TO PAY
          </Button>
          <div style={{ marginTop: '10px' }}>
            By clicking on Proceed To Pay, you agree to our{" "}
            <a
              href="/refundpolicy"
              style={{ color: "blue" }}
            >
              Refund & Cancellation Policy
            </a>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default PaymentCard;
